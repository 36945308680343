import React from 'react'
import { graphql } from 'gatsby'
import Pricing from '../../components/Pricing'
import Layout from '../../components/Global/Layout'
import Loader from '../../components/Global/Loader'

const locale = 'en-US'

export const query = graphql`
  {
    allContentfulCheckout {
      edges {
        node {
          steps {
            internal {
              content
            }
          }
          stepOutlineTitle
          stepsOutline {
            internal {
              content
            }
          }
          pricingConfigurator {
            internal {
              content
            }
          }
          countryOptions {
            internal {
              content
            }
          }
          serviceLevels {
            internal {
              content
            }
          }
          applicantInformation {
            internal {
              content
            }
          }
          trademarkInformation {
            internal {
              content
            }
          }
          checkout {
            internal {
              content
            }
          }
          filingDetails {
            internal {
              content
            }
          }
          node_locale
        }
      }
    }

    allContentfulPricingPage {
      edges {
        node {
          title
          bodyText0
          heading0
          stepHeading1
          bodyText1
          stepsOutline {
            internal {
              content
            }
          }
          bodyText2
          pricingConfigurator {
            internal {
              content
            }
          }
          stepHeading2
          bodyText3
          countryOptions
          stepHeading3
          bodyText4
          serviceLevels {
            internal {
              content
            }
          }
          cart {
            internal {
              content
            }
          }
          seoContent {
            internal {
              content
            }
          }
          node_locale
        }
      }
    }

    ca_flag: file(relativePath: { eq: "pricing_page/ca_flag.png" }) {
      childImageSharp {
        fixed(width: 45, height: 25) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    us_flag: file(relativePath: { eq: "pricing_page/us_flag.png" }) {
      childImageSharp {
        fixed(width: 45, height: 25) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ca_us_flag: file(relativePath: { eq: "pricing_page/ca_us_flag.png" }) {
      childImageSharp {
        fixed(width: 75, height: 20) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const PricingPage = (props) => {
  const {
    allContentfulCheckout,
    allContentfulPricingPage,
    ca_flag,
    us_flag,
    ca_us_flag
  } = props.data

  const pageData = allContentfulPricingPage?.edges.filter(
    (node) => node.node.node_locale === locale
  )[0].node

  const contentfulCheckoutData = allContentfulCheckout?.edges.filter(
    (node) => node.node.node_locale === locale
  )[0].node

  if (!locale) {
    return (
      <Layout url='/process' locale={locale}>
        <Loader loading />
      </Layout>
    )
  }

  return (
    <Layout
      locale={locale}
      url='/process'
      title={JSON.parse(pageData.seoContent.internal.content).title}
      desc={JSON.parse(pageData.seoContent.internal.content).desc}
    >
      <Pricing
        locale={locale}
        pageData={pageData}
        images={{ Canada: ca_flag, 'U.S.A': us_flag, Both: ca_us_flag }}
        contentfulCheckoutData={contentfulCheckoutData}
      />
    </Layout>
  )
}

export default PricingPage
